<template>
  <div class="d-flex flex-column mx-5" style="width: 100%">
    <div
      class="d-flex flex-row mt-5"
      style="width: 100%; justify-content: space-between; align-items: center;"
    >
      <span class="page-title">Agency Needs</span>
      <circle-icon-button
        text="Add Need"
        icon="mdi-plus"
        v-if="$route.params.id == profile._id"
        :onClicked="addNeed"
      />
    </div>
    <div>
      <v-card v-for="need in needs" :key="need._id" @click="goToDetails(need)">
        <v-card-text class="d-flex flex-row my-3">
          <div>
            <v-img
              :src="require('@/assets/svg/needs.svg')"
              width="60"
              height="60"
              contain
            />
          </div>
          <div class="d-flex flex-column mx-3" style="flex-grow:1">
            <span style="font-size: 16px; font-family: 'Poppins-SemiBold';">
              {{ need.title }}
            </span>
            <div
              style="font-size: 12px;font-family: 'Poppins-Regular';"
              class="d-flex"
            >
              <div
                class="url-button"
                @click.stop="goToAgencyProfile(need.agency)"
                @mousedown.stop
                @touchstart.stop
              >
                {{ need.agency_name }}
              </div>
            </div>
            <span
              style="color: #5b2cae; font-size: 12px;font-family: 'Poppins-Regular';"
            >
              {{ need.duration }}
            </span>
          </div>
          <div style="flex-shrink: 0">
            <v-tooltip bottom v-if="profile._id == need.agency">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                  small
                  @click.stop="onEdit(need)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#6038F7" style="margin-top: 2px;" size="18"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
              Edit Need
            </v-tooltip>
            <v-tooltip bottom v-if="profile._id == need.agency">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onDelete(need)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#6038F7" style="margin-top: 2px;" size="18"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              Delete Need
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="ml-2"
                  small
                  style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                  @click.stop="onShare(need)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#6038F7" size="18">mdi-share</v-icon>
                </v-btn>
              </template>
              Share Need
            </v-tooltip>
          </div>
        </v-card-text>
      </v-card>
      <div
        v-if="needs.length == 0"
        class="align-center justify-center d-flex mt-3"
        style="width: 100%; height: 100%;"
      >
        No posted needs
      </div>
    </div>
    <agency-create-need
      :dialog="createDialog"
      :onCloseDialog="onCloseCreateDialog"
    />
    <agency-edit-need
      :item="selected"
      :dialog="editDialog"
      :onCloseDialog="onCloseEditDialog"
    />
    <confirm-delete-need-dialog
      :deleteDialog="deleteDialog"
      :need="selected"
      :onClose="onCloseConfirmDelete"
      :onDeleted="onDeletedNeed"
    />
  </div>
</template>
<script>
import CircleIconButton from "@/components/common/CircleIconButton.vue";
import { mapActions, mapGetters } from "vuex";
import AgencyCreateNeed from "../needs/create/AgencyCreateNeed.vue";
import AgencyEditNeed from "../needs/create/AgencyEditNeed.vue";
import ConfirmDeleteNeedDialog from "../../../components/common/ConfirmDeleteNeedDialog.vue";
export default {
  components: {
    CircleIconButton,
    AgencyCreateNeed,
    AgencyEditNeed,
    ConfirmDeleteNeedDialog,
  },
  data() {
    return {
      loading: false,
      createDialog: false,
      needs: [],
      selected: null,
      editDialog: false,
      deleteDialog: false,
      snackbar: false,
      snackMessage: null,
    };
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
    }),
  },
  methods: {
    ...mapActions("need", {
      getNeeds: "fetchAgencyNeeds",
    }),
    loadData() {
      this.loading = true;
      this.getNeeds(this.$route.params.id)
        .then((res) => {
          this.needs = res;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    addNeed() {
      this.createDialog = true;
    },
    onCloseCreateDialog() {
      this.createDialog = false;
      this.loadData();
    },
    goToDetails(item) {
      this.$router.push({
        name: "agency-need-details",
        query: {
          _id: item._id,
        },
      });
    },
    onDelete(need) {
      this.selected = need;
      this.deleteDialog = true;
    },
    onDeletedNeed() {
      this.deleteDialog = false;
    },
    onEdit(need) {
      this.selected = need;
      this.editDialog = true;
    },
    onCloseEditDialog() {
      this.editDialog = false;
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    async onShare(need) {
      this.copyToClipboard(
        process.env.VUE_APP_WEB_URL + "/needs/details?_id=" + need._id
      );
      this.snackbar = true;
      this.snackMessage = "copied share url";
    },
    copyToClipboard(textToCopy) {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
      } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },
    goToAgencyProfile(agency) {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: agency },
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
.page-title {
  font-size: 20px;
  font-weight: 500;
  color: #472583;
}
</style>
